.kv {
  width: 100%;
  display: flex;
  &__mobile {
    display: block;
    @include tablet {
      display: none;
    }
  }

  &__desktop {
    display: none;
    @include tablet {
      display: block;
    }
  }
}

h1 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  @include tablet {
    font-size: 24px;
    margin-bottom: 30px;
  }
  @include desktop {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 45px;
  }
  @include wide {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 45px;
  }
  @include xwide {
    font-size: 35px;
    margin-top: 15px;
    margin-bottom: 45px;
  }

  br {
    display: none;
    @include tablet {
      display: block;
    }
  }
}

h2 {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  @include tablet {
    font-size: 18px;
    margin-bottom: 12px;
  }
  @include desktop {
    font-size: 20px;
  }
  @include wide {
    font-size: 19px;
  }
  @include xwide {
    font-size: 22px;
  }
}

ul {
  li {
    font-size: 13px;
    position: relative;
    padding-left: 16px;
    margin-bottom: 16px;
    @include tablet {
      font-size: 16px;
      padding-left: 24px;
      margin-bottom: 20px;
    }
    @include desktop {
      font-size: 18px;
    }
    @include wide {
      font-size: 18px;
    }
    @include xwide {
      font-size: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: '';
      top: 7px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      position: absolute;
      background: #FFC211;
      @include tablet {
        top: 10px;
        width: 6px;
        height: 6px;
      }
      @include desktop {
        top: 10px;
        width: 8px;
        height: 8px;
      }
      @include wide {
        top: 8px;
      }
      @include xwide {
        top: 10px;
      }
    }
  }
}

p {
  color: #fff;
  font-size: 14px;
  @include tablet {
    font-size: 16px;
  }
  @include desktop {
    font-size: 18px;
  }
  @include xwide {
    font-size: 20px;
  }
}

.benefit {
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__block {
    width: 100%;
    margin-bottom: 30px;
  }

  img {
    display: block;
    max-width: 60%;
    margin: 0 auto;
    @include tablet {
      max-width: 70%;
    }
    @include desktop {
      max-width: 80%;
    }
    @include wide {
      max-width: 80%;
    }
    @include xwide {
      max-width: none;
    }
  }
}

.notice {
  padding: 40px 0;
  background: #1FB25A;
  &__text {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1000px;
    text-align: center;
    p {
      margin-bottom: 14px;
      @include tablet {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.video {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    z-index: 10;
    cursor: pointer;
    position: relative;
    transition: all .4s ease-in;
    &.isHidden {
      opacity: 0;
      z-index: 0;
      transition: all .4s ease-in;
    }
  }
  video {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}