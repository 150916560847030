:root {
	--container-width: 1200px;
	--container-padding: 15px;

	--font-main: 'Inter', sans-serif;

	--color-gold: #FFCC80;
	--color-grey: #7D7D7D;
	--color-brown: #5B2910;

	--page-bg: #000;
	--text-color: #000;

    --laptop-size: 1199px;
	--tablet-size: 959px;
	--mobile-size: 599px;
}
