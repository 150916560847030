html {
	overflow-x: hidden;
	background-color: #fff;
	scroll-behavior: smooth;
	font-family: var(--font-main);
}

body {
	background: #fff;
	overflow-x: hidden;
	font-family: var(--font-main);
}

.container {
	@include container();
}

img {
	display: block;
}

a {
	color: var(--link-color);
}
