/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/base';
@import './base/utils';

/* Blocks */
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "sections/_first.scss";

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */
