.footer {
  z-index: 2;
  padding: 40px 0;
  margin-top: -2px;
  position: relative;
  background: #1FB25A;
  &--wrapper {
    display: flex;
    row-gap: 20px;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 800px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      text-align: left;
      flex-direction: row;
    }
  }

  &__logo {
    max-width: 160px;
    @include tablet {
      max-width: 200px;
    }
    img {
      width: 100%;
    }
  }
  &__text {
    p {
      color: #fff;
      font-size: 12px;
      @include tablet {
        font-size: 13px;
      }
      @include desktop {
        font-size: 14px;
      }
    }
  }
}